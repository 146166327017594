.error {
    color: red;
    background: lightgrey;
    font-size: 20px;
    border-style: solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

h1 {
    color: green;
    font-style: italic;
}

li {
    color: grey;
    padding-top: 3px;
    font-size: 12px;
}


.person {
    color: red;
    padding-top: 5px;
    font-size: 15px;
}